<template>
  <div id="subPageLayout">
    <div class="layout-left">
      <div class="user-box">
        <img :src="user.img" />
        <p>{{ user.name }}</p>
      </div>
      <ul>
        <li v-for="(item, index) in stateArr" :key="index">
          <p v-text="item.num" :class="{ on: item.color }"></p>
          <p v-text="item.state">全部</p>
        </li>
      </ul>
    </div>
    <div class="layout-right">
      <slot name="rightmain"></slot>
      <p v-if="isEmptyData" class="empty-data">暂无数据!</p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "subpagelayout",
  props: {
    stateArr: Array,
    isEmptyData: Boolean
  },
  computed: mapState(["user"])
};
</script>
<style lang="less">
#subPageLayout {
  width: 1200px;
  margin: 18px auto;
  display: flex;
  justify-content: space-between;
  .layout-left {
    width: 284px;
    height: min-content;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    .user-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      img {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        margin-top: 30px;
        margin-bottom: 14px;
      }
      p {
        width: 100%;
        font-size: 18px;
        color: #333333;
        text-align: center;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      margin: 40px 0;
      li {
        border-right: 1px solid #e5e5e5;
        padding: 0 20px;
        p {
          text-align: center;
          &:nth-child(1) {
            font-size: 22px;
            color: #777;
            &.on {
              color: #17ad84;
            }
          }
          &:nth-child(2) {
            font-size: 14px;
            color: #2c2824;
          }
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .layout-right {
    width: 900px;
    .empty-data {
      width: 100%;
      line-height: 60px;
      text-align: center;
      background-color: #fff;
      font-size: 16px;
      color: #777777;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
    }
  }
}
</style>
