<template>
  <div id="messagePage" v-cloak>
    <subpagelayout :stateArr="stateArr" :isEmptyData="isEmptyData">
      <template v-slot:rightmain>
        <ul class="message-list" v-if="!isEmptyData">
          <li
            :class="{ on: !item.IsRead }"
            v-for="(item, index) in messageList"
            :key="index"
          >
            <router-link
              :to="{
                path: 'submitInfo',
                query: { id: item.ReportID, selfId: item.ID }
              }"
            >
              <div class="center">
                <div class="center-left">
                  <p></p>
                  <span>{{ item.Title }}</span>
                </div>
                <div class="center-right">
                  <span>{{
                    item.PostTime | dateFormat("yyyy-MM-dd HH:mm:ss")
                  }}</span>
                  <i @click.stop="deleteMsg(item.ID, index)"></i>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </template>
    </subpagelayout>
  </div>
</template>

<script>
import subpagelayout from "../../components/subPageLayout";
export default {
  data() {
    return {
      stateArr: [
        {
          num: "",
          state: "系统消息",
          color: false
        },
        {
          num: "",
          state: "未读消息",
          color: true
        }
      ],
      isEmptyData: false,
      a: 1,
      messageList: []
    };
  },
  components: {
    subpagelayout
  },
  methods: {
    // 删除消息
    deleteMsg(id, index) {
      this.$http
        .post("report/api/MsgUDelete", {
          account: this.$store.state.user.account,
          id
        })
        .then(response => {
          if (response.data.state) {
            this.messageList.pop(index);
            this.$message.success("删除消息成功!");
            if (this.messageList.length === 0) {
              this.isEmptyData = true;
            }
          } else {
            console.log(response.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    // 获取用户消息数量
    this.$http
      .get("report/api/GetUMsgCount", {
        params: {
          account: this.$store.state.user.account
        }
      })
      .then(response => {
        if (response.data.state) {
          this.stateArr[0].num = response.data.data[0].Num;
          this.stateArr[1].num = response.data.data[1].Num;
        } else {
          this.$message.error(response.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
    // 获取消息列表
    this.$http
      .get("report/api/getmsglistbyuseraccount", {
        params: {
          account: this.$store.state.user.account
        }
      })
      .then(response => {
        if (response.data.state) {
          if (response.data.data.length > 0) {
            this.messageList = response.data.data;
          } else {
            this.isEmptyData = true;
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  beforeRouteLeave(to, from, next) {
    // 标为已读
    this.$http
      .post("report/api/MsgUpIsRead", {
        account: this.$store.state.user.account,
        id: to.query.selfId
      })
      .then(() => {
        next();
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang="less">
#messagePage {
  .message-list {
    margin-top: -18px;
    li {
      width: 100%;
      height: 106px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      margin-top: 18px;
      .center {
        width: 94%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .center-left {
          display: flex;
          align-items: center;
          p {
            width: 48px;
            height: 48px;
            background: #e7f4ea url("../../assets/25.png") center center
              no-repeat;
            border-radius: 50%;
            margin-right: 16px;
            position: relative;
          }
          span {
            font-size: 14px;
            color: #333333;
          }
        }
        .center-right {
          span {
            font-size: 14px;
            color: #cdcdcd;
            padding-right: 20px;
          }
          i {
            display: inline-block;
            width: 13px;
            height: 13px;
            background: url("../../assets/24.png") no-repeat;
            cursor: pointer;
          }
        }
      }
      &.on {
        .center-left p::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #dd3744;
          border-radius: 50%;
          position: absolute;
          right: 5px;
          top: 5px;
        }
      }
    }
  }
}
</style>
